/** @format */

import { faSirenOn } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, AlertTitle, Box, Button } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { makeStyles, styled } from '@mui/styles';
import LoadingOverlay from '@ronchalant/react-loading-overlay';
import { useUnleashContext } from '@unleash/proxy-client-react';
import axios from 'axios';
import clsx from 'clsx';
import Lottie from 'lottie-react';
import { ConfirmProvider } from 'material-ui-confirm';
import moment from 'moment';
import 'moment/locale/fr';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { ClearBrowserCache } from 'react-clear-browser-cache';
import { isAndroid, isIOS, isMobileOnly } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { api, CONSTANTS } from '../api.ts';
import sun from '../assets/animations/sun.json';
import { login, logout, selectAuth } from '../redux/authSlice';
import { ThemeProvider } from '../theme';
import CacheManager from '../utils/cache';
import { useManywatt } from '../utils/context';
import { getQueryParams } from '../utils/url';
import { UserWebSocketProvider } from '../websocket/UserWebSocket';
import AdminMessageManager from './app/admin/AdminMessageManager';
import AdminOptionCompatibilityManager from './app/admin/AdminOptionCompatibilityManager';
import AdminProductCompatibilityManager from './app/admin/AdminProductCompatibilityManager';
import AdminTypologyProductDependencyCompatibilityManager from './app/admin/AdminTypologyProductDependencyCompatibilityManager';
import CustomerAreaCredentialsManager from './app/customerArea/CustomerAreaCredentialsManager';
import MainMenu from './app/MainMenu';
import PDFViewer from './app/pdf/PDFViewer';
import UITest from './app/uitest/UITest';
import BoxLoader from './misc/BoxLoader';
import Gate from './misc/Gate';
import WebSocketNotification from './misc/NotistackVariants';

const LeadDashboard = lazy(() => import('./app/lead/LeadDashboard'));
const Lead = lazy(() => import('./app/lead/Lead'));
const LeadV2 = lazy(() => import('./app/leads/v2/Lead'));
const ClientDashboard = lazy(() => import('./app/client/ClientDashboard'));
const ClientDashboardV2 = lazy(() => import('./app/clientV2/ClientDashboard'));
const ProjectManagersDashboard = lazy(() =>
  import('./app/stats/StatsDashboard'),
);
const ReviewerDashboard = lazy(() => import('./app/review/ReviewerDashboard'));
const CalendarDashboard = lazy(() =>
  import('./app/calendar/CalendarDashboard'),
);
const InterventionDashboard = lazy(() =>
  import('./app/interventions/InterventionDashboard'),
);
const ConstructionEndDashboard = lazy(() =>
  import('./app/constructionEnd/ConstructionEndDashboard'),
);
const RequestDashboard = lazy(() => import('./app/requests/RequestDashboard'));
const Auth = lazy(() => import('./auth/Auth'));
const Adages = lazy(() => import('./adages/Adages'));
const BaseOffersDashboard = lazy(() =>
  import('./app/baseOffers/BaseOffersDashboard'),
);
const InternetOfferDashboard = lazy(() =>
  import('./app/baseOffers/v2/InternetOfferDashboard'),
);
const MessageQueueDashboard = lazy(() =>
  import('./app/messageQueueManager/MessageQueueDashboard'),
);
const TechniciansDashboard = lazy(() =>
  import('./app/technicians/TechniciansDashboard'),
);
const OrderForceSync = lazy(() => import('./app/orders/OrderForceSync'));
const ForceReloadOffers = lazy(() =>
  import('./app/leads/v2/quotes/ForceReloadOffers'),
);
const BigChangeConfigDashboard = lazy(() =>
  import('./app/bigchange/BigChangeConfigDashboard'),
);
const LayoutDashboard = lazy(() => import('./app/layout/LayoutDashboard'));
const QuoteVariantsDashboard = lazy(() =>
  import('./app/similarOffers/v2/QuoteVariantsDashboard'),
);

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  padding: {
    [theme.breakpoints.up('md')]: {
      marginBottom: -80,
      //      paddingBottom: 80,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: -200,
      paddingBottom: 200,
    },
    paddingTop: 30,
  },
  loadingWrapper: {
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 99999,
    '& ._loading_overlay_overlay': {
      zIndex: 99999,
      // backgroundImage: 'linear-gradient(136deg, #408b6a 0%, #50AE85 50%, #72be9d 100%)',
      backgroundColor: theme.palette.primary.main,
    },
    '& ._loading_overlay_content': {
      fontSize: 26,
    },
    '& ._loading_overlay_spinner': {
      width: 100,
      '& svg > circle': {
        strokeWidth: 3,
      },
    },
  },
  alertIcon: {
    '--fa-primary-color': theme.palette.warning.dark,
    '--fa-secondary-color': theme.palette.warning.light,
    '--fa-primary-opacity': 1,
    '--fa-secondary-opacity': 1,
  },
}));

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: `${theme.palette.error.main} !important`,
    },
    '&.notistack-MuiContent-info': {
      backgroundColor: `${theme.palette.info.main} !important`,
    },
    '&.notistack-MuiContent-warning': {
      backgroundColor: `${theme.palette.warning.main} !important`,
    },
  }),
);

function Main() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { user, isAuthenticated } = useSelector(selectAuth);
  const { honeybadger } = useManywatt();
  //  const updateContext = useUnleashContext();

  moment.locale('fr');

  const isAdages =
    window.location &&
    window.location.pathname &&
    window.location.pathname ===
      '/realtime/adages/c9235ccadc50ff06a552d846fc479966';

  const loadConfig = async () => {
    try {
      const configFetch = await fetch('/config/env.json');
      if (configFetch.ok) {
        const config = await configFetch.json();
        axios.defaults.baseURL = config.API_URL;
      }
    } catch (e) {
      /* empty */
    }
  };

  const load = async () => {
    try {
      const response = await api.get('/whoami/');
      const result = response.data;
      const isStandalone =
        getQueryParams(window.location.search).standalone === '1';
      if (
        result &&
        result.has_access_to_gate &&
        isStandalone &&
        window.location.pathname !== '/gate' &&
        isMobileOnly &&
        (isIOS || isAndroid)
      ) {
        window.location.href = '/gate';
      }
      // await updateContext({ userId: result.email });
      try {
        window.YTFeedbackForm.getClientJSApi(
          'c99e083f-bacc-446b-b903-e48f56ada531',
        )
          .then((form) => {
            const blocks = form.getBlocks();
            const emailBlock = blocks.find((b) => b.type === 'email');
            form.setBlockValue(emailBlock.title, result.email);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (err) {
        console.error(err);
      }
      dispatch(login(result));
      if (honeybadger) {
        honeybadger.setContext({
          user_id: result.id,
          user_email: result.email,
        });
      }
      CacheManager.instance.updateCache();
      /* if (result.data && !result.data.is_logged_with_google) {
        window.location.href = `${url}/google/oauth2-authorize/`;
      } */
    } catch (err) {
      console.error(err);
      dispatch(logout());
      throw Error();
    }
  };

  useEffect(() => {
    async function fetchData() {
      await loadConfig();
      if (!isAdages) {
        await load()
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <CssBaseline />
      {/* user && user.slack_feedback_token && (
          <CustomFeedback
            slackToken={user.slack_feedback_token}
            slackChannel="C01REKALWNT"
            handleSubmitError={() => null}
          />
        ) */}
      <LoadingOverlay
        active={loading}
        spinner={
          <Lottie
            animationData={sun}
            loop
            autoplay
            style={{
              height: 200,
              width: 200,
            }}
          />
        }
        // text="Chargement"
        className={classes.loadingWrapper}
      />
      {/* <ClearBrowserCache>
        {(contextValue) => (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              right: 0,
              width: '600px',
              height: '50px',
              zIndex: 9999999,
              display:
                !contextValue.isLatestVersion || isAdages ? 'none' : 'block',
              padding: 1,
            }}
          >
            <Alert
              severity="warning"
              icon={
                <FontAwesomeIcon
                  icon={faSirenOn}
                  fixedWidth
                  className={classes.alertIcon}
                />
              }
              action={
                <>
                  <div>
                    <Button
                      color="inherit"
                      size="small"
                      onClick={contextValue.clearCacheAndReload}
                    >
                      Mettre à jour
                    </Button>
                  </div>
                </>
              }
              sx={{
                borderRadius: 3,
              }}
            >
              <AlertTitle>
                <strong>
                  Une nouvelle version de Manywatt est disponible.
                </strong>
              </AlertTitle>
              Veuillez terminer toute tâche en cours avant la mise à jour.
            </Alert>
          </Box>
        )}
      </ClearBrowserCache> */}
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent,
          warning: StyledMaterialDesignContent,
          webSocketNotification: WebSocketNotification,
        }}
      >
        <UserWebSocketProvider>
          <ConfirmProvider>
            {!loading && (
              <Suspense
                fallback={
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <BoxLoader loading>
                        <div style={{ width: 100, height: 100 }} />
                      </BoxLoader>
                    </Grid>
                  </Grid>
                }
              >
                {isAdages ? (
                  <Adages />
                ) : (
                  <BrowserRouter>
                    <div
                      className={clsx(
                        classes.root,
                        isAuthenticated ? classes.padding : undefined,
                      )}
                    >
                      {isAuthenticated && user && user.has_access_to_front && (
                        <MainMenu />
                      )}
                      <div>
                        <Routes>
                          {isAuthenticated &&
                          user &&
                          user.has_access_to_front ? (
                            <>
                              {(user.is_superuser ||
                                user.is_marketing ||
                                user.is_marketing_only ||
                                user.is_commercial_manager) && (
                                <Route
                                  path="/baseoffers"
                                  element={<BaseOffersDashboard />}
                                />
                              )}
                              <Route
                                path="/v2/internet-offers"
                                element={<InternetOfferDashboard />}
                              />
                              {(user.is_technician_manager ||
                                user.is_superuser) && (
                                <>
                                  <Route
                                    path="/technicians"
                                    element={<TechniciansDashboard />}
                                  />
                                  <Route
                                    path="/bigchange"
                                    element={<BigChangeConfigDashboard />}
                                  />
                                </>
                              )}
                              {((user.is_staff && user.is_admin_leader) ||
                                user.is_superuser) && (
                                <Route
                                  path="/order-sync"
                                  element={<OrderForceSync />}
                                />
                              )}
                              {user.is_superuser && (
                                <Route
                                  path="/admin/reload-offers"
                                  element={<ForceReloadOffers />}
                                />
                              )}
                              {!user.is_marketing_only ? (
                                <>
                                  <Route
                                    path="/leads"
                                    element={<LeadDashboard />}
                                  />
                                  <Route
                                    path="/lead/v1/:id/*"
                                    element={<Lead />}
                                  />
                                  <Route
                                    path="/lead/:id/*"
                                    element={<LeadV2 />}
                                  />
                                  <Route
                                    path="/lead/v2/:id/*"
                                    element={<LeadV2 />}
                                  />
                                  {!user.is_external_accountant && (
                                    <Route
                                      path="/calendar"
                                      element={<CalendarDashboard />}
                                    />
                                  )}
                                  {(user.is_reviewer || user.is_superuser) && (
                                    <Route
                                      path="/review"
                                      element={<ReviewerDashboard />}
                                    />
                                  )}
                                  {(user.is_superuser ||
                                    user.is_project_manager ||
                                    user.is_stats_editor) && (
                                    <Route
                                      path="/projectmanagers"
                                      element={
                                        <ProjectManagersDashboard user={user} />
                                      }
                                    />
                                  )}
                                  <Route
                                    path="/construction-review"
                                    element={
                                      <ConstructionEndDashboard user={user} />
                                    }
                                  />
                                  {(user.is_superuser ||
                                    user.is_admin_leader ||
                                    user.is_project_manager ||
                                    user.is_external_accountant ||
                                    user.is_calendar_manager) && (
                                    <Route
                                      path="/interventions"
                                      element={<InterventionDashboard />}
                                    />
                                  )}
                                  <Route
                                    path="/requests"
                                    element={<RequestDashboard />}
                                  />
                                  <Route
                                    path="/messagequeuemanager"
                                    element={<MessageQueueDashboard />}
                                  />
                                  {user.is_superuser && (
                                    <Route
                                      path="/customer-area-credentials"
                                      element={
                                        <CustomerAreaCredentialsManager />
                                      }
                                    />
                                  )}
                                  {(user.is_superuser ||
                                    user.is_marketing ||
                                    user.is_marketing_only ||
                                    user.is_commercial_manager) && (
                                    <Route
                                      path="/quote-variants"
                                      element={<QuoteVariantsDashboard />}
                                    />
                                  )}
                                  <Route
                                    path=""
                                    element={<Navigate to="/leads" replace />}
                                  />
                                  <Route
                                    path="/login"
                                    element={<Navigate to="/leads" replace />}
                                  />
                                </>
                              ) : (
                                <>
                                  <Route
                                    path=""
                                    element={
                                      <Navigate to="/baseoffers" replace />
                                    }
                                  />
                                  <Route
                                    path="/login"
                                    element={
                                      <Navigate to="/baseoffers" replace />
                                    }
                                  />
                                </>
                              )}
                              {user.has_access_to_gate && (
                                <Route path="/gate" element={<Gate />} />
                              )}
                              <Route
                                path="/clients/:page"
                                element={<ClientDashboard />}
                              />
                              <Route
                                path="/clients"
                                element={
                                  <Navigate
                                    to={`/clients/${
                                      !user.is_marketing_only
                                        ? 'general'
                                        : 'planning'
                                    }`}
                                    replace
                                  />
                                }
                              />
                              {user.is_superuser && (
                                <>
                                  <Route
                                    path="/layout"
                                    element={<LayoutDashboard />}
                                  />
                                  <Route path="/uitest" element={<UITest />} />
                                  <Route
                                    path="/clientsv2/:page"
                                    element={<ClientDashboardV2 />}
                                  />
                                  <Route
                                    path="/clientsv2"
                                    element={
                                      <Navigate
                                        to="/clientsv2/coucou"
                                        replace
                                      />
                                    }
                                  />
                                  <Route
                                    path="/admin/message"
                                    element={<AdminMessageManager />}
                                  />
                                  <Route
                                    path="/admin/product-compatibilities"
                                    element={
                                      <AdminProductCompatibilityManager />
                                    }
                                  />
                                  <Route
                                    path="/admin/product-dependency-compatibilities"
                                    element={
                                      <AdminTypologyProductDependencyCompatibilityManager />
                                    }
                                  />
                                  <Route
                                    path="/admin/option-compatibilities"
                                    element={
                                      <AdminOptionCompatibilityManager />
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <Route path="*" element={<Auth />} />
                          )}
                        </Routes>
                      </div>
                    </div>
                  </BrowserRouter>
                )}
              </Suspense>
            )}
          </ConfirmProvider>
        </UserWebSocketProvider>
      </SnackbarProvider>
      <ThemeProvider>
        <PDFViewer />
      </ThemeProvider>
    </>
  );
}

Main.propTypes = {};

export default Main;
